import React from 'react'
import styled from 'styled-components'
import logo from '../assets/DigitalAwarenessLogo.png'

const Wrapper = styled.div`

`

const LogoImage = styled.img`
	height: 2.75rem;
	margin: 0.25rem 0;
`

export default function Logo() {
	return (
		<Wrapper>
			<LogoImage src={logo} alt='' />
		</Wrapper>
	)
}
