import React from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import { Flex } from '../utils/styles'

const Wrapper = styled(Flex)`
	height: 2.5rem;
	justify-content: flex-start;
	align-items: flex-start;
`

export default function Header() {
	return (
		<Wrapper>
			<Logo />
		</Wrapper>
	)
}
