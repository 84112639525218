const theme = {
	darkBlue: '#01074A',
	blue: '#084597',
	silver: '#A3A4A6',
	lightSilver: '#F3F4F6',
	loxoneGreen: '#68C550',
	white: '#fefefe',
	darkGrey: '#797979',
	darkGrey2: '#707070',
	lightGrey: '#E2E2E2',

}

export default theme