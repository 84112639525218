import { useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Splash from './components/Splash';
import Content from './components/Content';
import Footer from './components/Footer';
import styled from 'styled-components';
import { useGlobalData } from './utils/useGlobalData';

const AppContainer = styled.div`
  width: 100%;
  max-width: 56rem;
  align-items: center;
  margin: 0 auto;
  background-color: #FFF;
  min-height: 100vh;
  overscroll-behavior-y: none;
  ${props => props.isMobile && `
    padding: 0;
    width: 100%;
  `}
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 5rem);
`

function App() {

  const { setCurrentWidth, currentDevice } = useGlobalData()

	useEffect(() => {
		setCurrentWidth(window.innerWidth)
		window.addEventListener("resize",	() => setCurrentWidth(window.innerWidth));
		return () => {
			window.removeEventListener("resize", () => setCurrentWidth(window.innerWidth));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

	const isMobile = ['mobile', 'small'].includes(currentDevice)
  
  return (
      <AppContainer isMobile={isMobile}>
        <Header />
          <BodyContainer>
            <Splash />
            <Content />
          </BodyContainer>
        <Footer />
      </AppContainer>
  );
}

export default App;
