import React from 'react'
import styled from 'styled-components'
import sanDiego from '../assets/san-diego.png'
import { Flex } from '../utils/styles'
import theme from '../utils/theme'
import { useGlobalData } from '../utils/useGlobalData'


const Wrapper = styled(Flex)`
	width: 100%;
	flex-direction: ${props => props.isMobile ? 'column' : 'row-reverse'};
	position: relative;
`

const SanDiegoImage = styled.img`
	width: calc(100% - 16rem);
	height: ${props => props.isMobile ? '15rem' : '18rem'};
	object-fit: cover;
	object-position: center center;
	${props => props.isMobile && `
		min-width: 24rem;
		width: calc(100%);
	`}
`

const HeadlineText = styled(Flex)`
	background-color: ${theme.lightGrey};
	height: ${props => props.isMobile ? '2.5rem' : '18rem'};
	text-align: center;
	font-weight: 700;
	width: calc(100%);
	${props => !props.isMobile && `
		word-spacing: 9999999px;
		text-align: left;
		align-items: center;
		font-size: 2rem;
		width: 16rem;
		
		span {
			padding-left: 2.5rem;
		}
	`}
	color: ${theme.black};

	${props => props.isMobile && `
		min-width: 24rem;
	`}

	
`

export default function Splash() {

	const { currentDevice } = useGlobalData()
	const isMobile = ['mobile', 'small'].includes(currentDevice)

	const headlineText = isMobile
		? 'Technology that enhances everyday life'
		: 'Technology That Enhances Everyday Life'
		
	return (
		<Wrapper isMobile={isMobile}>
			<SanDiegoImage isMobile={isMobile} src={sanDiego} alt='san diego' />
			<HeadlineText isMobile={isMobile}>
				<span>{headlineText}</span>
				</HeadlineText>
		</Wrapper>
	)
}
