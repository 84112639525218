import React from "react";
import styled from "styled-components";
import { Flex } from "../utils/styles";
import theme from "../utils/theme";

const Wrapper = styled(Flex)`
  height: 2.5rem;
  background-color: ${theme.darkGrey};
  width: 100%;
  color: ${theme.white};
  font-size: 0.75rem;
`;

export default function Footer() {
  const d = new Date();
  const year = d.getFullYear();
  return <Wrapper>© {year} Digital Awareness, Inc.</Wrapper>;
}
