import React from 'react'
import styled from 'styled-components'
import automationsdMobile from '../assets/automationsd-mobile.png'
import automationsdDesktop from '../assets/automationsd-desktop.png'
import { Flex } from '../utils/styles'
import theme from '../utils/theme'
import { useGlobalData } from '../utils/useGlobalData'

const Wrapper = styled(Flex)`
	justify-content: flex-start;
	padding-top: 1rem;
	padding-bottom: 3rem;
`

const ContentItemImage = styled.img`
	margin-top: 1rem;
	box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
	cursor: pointer;
	transition: 0.1s all ease;
	position: relative;
	top: 0rem;
	:hover {
		top: -0.125rem;
		box-shadow: 0px 3px 6px rgba(0,0,0,0.32);
	}
`

const H3 = styled.h3`
	font-size: 1rem;
	color: ${theme.darkGrey2};
`

const SubHeading = styled.div`
	font-size: 0.75rem;
	color: ${theme.silver};
`


export default function Content() {

	const { currentDevice } = useGlobalData()
	const isMobile = ['mobile', 'small'].includes(currentDevice)

	return (
		<Wrapper column>
			<H3>Products & Services Division:</H3>
			<SubHeading>click below to visit</SubHeading>
			<a href='https://www.automationsd.com'>
				<ContentItemImage src={isMobile ? automationsdMobile : automationsdDesktop} alt='AutomationSD' />
			</a>
		</Wrapper>
	)
}
