import styled from "styled-components"
import theme from "./theme"

const Flex = styled.div`
	color: ${theme.darkBlue};
	display: flex;
	flex-direction: ${props => props.column ? 'column' : 'row'};
	align-items: ${props => props.alignItems || 'center'};
	justify-content: ${props => props.justifyContent || 'center'};
`

export {
	Flex
}